<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/boutique'}">馆藏精品</router-link> > 馆藏文物详情</div>
			<div class="wapper flex">
				<div class="left">
					<div class="demo-image__preview">
						<el-image 
							:src="detail.cover" 
							:preview-src-list="srcList">
						</el-image>
						<p><span>点击图片查看大图<img src="@/assets/images/fangda.png"/></span></p>
					</div>
				</div>
				<div class="right">
					<h2>{{detail.title}}</h2>
					<p>{{detail.intro}}</p>
					<div class="content" v-html="detail.content">
					</div>
				</div>

				
			</div>
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				id: '',
				srcList: [],
				detail: {},
				
				
				
			}
		},
		created() {
			this.id = this.$route.query.id
			
		},
		mounted() {
			this.getDetail()
		},
		methods: {
			getDetail() {
				let formData = new FormData()
					formData.append('id', this.id)
				this.$http({
					url: `/collectBoutiqueApi/queryCollectBoutiqueDetail`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.detail = res.data
						this.srcList.push(res.data.cover)
						
					}
					
				});
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FAFAFA;
	}
	.wapper{
		padding: 60px;
		.left{
			margin-right: 60px;
			.el-image{
				width: 360px;
				height: 360px;
			}
			p{
				display: flex;
				justify-content: center;
				margin-top: 20px;
				span{
					display: flex;
					align-items: center;
					background: #FFF;
					padding: 5px 20px;
					border-radius: 15px;
					color: #595E63;
					font-size: 16px;
					box-shadow: 0px 0px 10px #F1F1F1;
					cursor: pointer;
				}
				img{
					width: 20px;
					height: 20px;
					margin-left: 10px;
				}
			}
		}
		.right{
			color: #595E63;
			font-size: 16px;
			h2{
				font-size: 30px;
			}
			>p{
				background: url('../../assets/images/shuxinig.png') no-repeat;
				width: 360px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				margin-top: 10px;
				overflow: hidden;
			}
			.content{
				font-size: 16px;
				line-height: 25px;
				padding: 20px 0;
				::v-deep img{
					max-width: 100% !important;
					display: inline-block;
				}
			}
		}

		
		
	}
}
	
</style>
